/* You can add global styles to this file, and also import other style files */

$primary: #48877F;
$primary-bg-subtle: #e2f5f3;
$secondary: #6b7785;
$secondary-bg-subtle: #f5f7f9;
$success: #00b289;
$success-bg-subtle: #e2f5e9;
$info: #00b2ff;
$info-bg-subtle: #e2f5ff;
$warning: #FBB25D;
$warning-bg-subtle: #fff5e2;
$danger: #ff4141;
$danger-bg-subtle: #ffe2e2;
$light: #f8f9fa;
$light-bg-subtle: #f8f9fa;
$dark: #343a40;
$dark-bg-subtle: #e2e6ea;

$enable-shadows: false;
$enable-gradients: false;
$enable-transitions: true;

$min-contrast-ratio: 2.5;

$font-family-sans-serif: 'Inter', sans-serif;
$font-family-base: $font-family-sans-serif;
$position-values: (
  0: 0,
  10: 10%,
  15: 15%,
  20: 20%,
  25: 25%,
  30: 30%,
  40: 40%,
  50: 50%,
  60: 60%,
  70: 70%,
  75: 75%,
  80: 80%,
  90: 90%,
  95: 95%,
  100: 100%
);
$width-values: (
  5: 5%,
  10: 10%,
  15: 15%,
  20: 20%,
  25: 25%,
  30: 30%,
  35: 35%,
  40: 40%,
  45: 45%,
  50: 50%,
  55: 55%,
  60: 60%,
  65: 65%,
  70: 70%,
  75: 75%,
  80: 80%,
  85: 85%,
  90: 90%,
  95: 95%,
  100: 100%
);

@each $key, $value in $width-values {
  .w-#{$key} {
    width: $value !important;
  }
}

$utilities: (
  "viewport-height": (
    property: height,
    class: vh,
    values: (
      25: 25vh,
      50: 50vh,
      75: 75vh,
      100: 100vh
    )
  )
);

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
@import "bootstrap-icons/font/bootstrap-icons.min.css";
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
@import 'ngx-sharebuttons/themes/circles';

@media print {
  .main-header {
    display: none !important;
  }
  
  .main-footer {
    display: none !important;
  }
}

html, body {
  width: 100%;
  // height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  background-color: #f5f6f8;
}

header {
  height: 5rem;
}

app-header, app-header-admin {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1020;
  // background-color: #fff;
}

.main {
  margin-top: 75px;
  min-height: 63vh;
  // margin-bottom: 100px;
}

.iti {
  display: block !important;
  width: 100%;
}

.numbered-list {
  padding-left: 0;
  margin: 1rem -3.5rem 1rem 3.5rem;
  text-align: center;
  list-style-type: none;
}

.numbered-list li {
  display: inline-flex;
  position: relative;
  margin-right: 4rem;
  font-weight: 600;
  color: var(--bs-primary-bg-subtle);
}

.numbered-list li::before {
  content: attr(data-step);
  display: inline-block;
  background-color: var(--bs-primary-bg-subtle); /* Circle background color */
  color: var(--bs-white); /* Text color */
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  font-weight: bold;
  margin-right: 0.5rem;
  position: absolute;
  left: calc(-4rem + 20px);
  top: 50%;
  transform: translateY(-50%);
}

.numbered-list li.active {
  color: var(--bs-primary);
}

.numbered-list li.active::before {
  background-color: var(--bs-primary);
}

.numbered-list li.done::before {
  content: '✔';
  color: var(--bs-white);
  background-color: #93E77E;
}

.list-inline-item:not(:last-child) {
  margin-right: 4rem;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .numbered-list {
    display: flex;
    justify-content: center;
    margin: 4rem -2rem 1rem 2rem;
  }
  
  .numbered-list li span {
    display: none;
  }

  .numbered-list li {
    display: inline-flex;
    margin-right: 0;
  }

  .numbered-list li:not(:last-child)::after {
    content: '';
    width: 2rem;
    position: absolute;
    padding: 0;
    margin-left: -12px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.5);
  }

  .numbered-list li.done::before {
    content: '✔';
    color: var(--bs-white);
    background-color: #93E77E;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: none;
}

.overlay.show {
  display: block;
}

#banners>.owl-carousel .owl-item img {
  width: 100%;
  height: auto;
  border-radius: 12px;
  object-fit: cover;
}

#banners>.owl-carousel .owl-item {
  aspect-ratio: 1328 / 360;
}

@media (max-width: 768px) {
  #banners>.owl-carousel .owl-item {
    aspect-ratio: 4 / 3;
    width: 100%;
    height: 100% !important;
    // object-fit: cover;
  }

  #banners>.owl-carousel .owl-item img {
    height: 100%;
    border-radius: 12px;
    object-fit: cover;
  }
}

.owl-theme .owl-dots .owl-dot span {
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: #d9d9d9;
  border: 1px solid #d9d9d9;
  margin: 0 5px;
  transition: all 0.6s ease;
}

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  width: 30px;
  background-color: var(--bs-primary);
  border: 1px solid var(--bs-primary);
  transition: all 0.3s ease;
}

.navbar-toggler-icon {
  width: 1rem;
  height: 1rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.modal { backdrop-filter: blur(2px); }

/* Add this to your styles.css or component-specific CSS file */
.sidebar {
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1020;
  padding-top: 20px;
  transition: all 0.3s;
}

.sidebar-header {
  padding: 0 15px;
}

.nav-link {
  padding: 10px 15px;
}

.nav-link:hover {
  background-color: #f8f9fa;
}

// .main {
//   margin-left: 250px; /* Adjust based on sidebar width */
// }

@media (max-width: 768px) {
  .sidebar {
    display: none; /* Hide sidebar on small screens */
  }
  .main {
    margin-left: 0; /* Adjust main content margin */
  }
}

/* For Webkit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #888;
}